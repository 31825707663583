<!--
 * @Autor: syq
 * @Date: 2021-07-11 15:22:57
-->
<template>
  <div id="storeCart">
    <div v-for="item in this.data" :key="item.id">
      <div class="top">
        <div class="left">
          <img src="../../../assets/logo.png" alt="" />
        </div>
        <div class="right">
          <div class="right-top">
            <h4>{{ item.name }}</h4>
            <a @click="() => jumpstore(item)">进入店铺 >></a>
          </div>
          <div class="right-content">
            <p>
              {{ item.description }}
            </p>
          </div>
        </div>
      </div>
      <div class="bottom">
        <van-grid :border="false" :column-num="4">
          <van-grid-item
            v-for="product in item.itemResList"
            :key="product.index"
          >
            <CommodityCard2
              :Productname="product.name"
              :Price="product.price"
              :Src="product.mediaList[0].url"
            />
          </van-grid-item>
        </van-grid>
      </div>
    </div>
  </div>
</template>

<script>
import CommodityCard2 from "../../../components/CommodityCard2.vue";
export default {
  components: { CommodityCard2 },
  data() {
    return {
      // storename:"dianpu",
      // description:"ssssss",
      // spuResList:[
      //   {
      //     productname:"2222",
      //     price:"100"
      //   }
      // ]
    };
  },
  mounted() {
  },
  methods: {
    jumpstore(item) {
      this.$router.push({
        path: "/storehome",
        query: { storeCode: item.code },
      });
    },
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="less" scoped>
#storeCart {
  width: 100%;
  .top {
    width: 100%;
    display: flex;
    .left {
      width: 100px;
      padding: 10px;
      img {
        width: 80px;
      }
    }
    .right {
      flex: 1;
      .right-top {
        width: 100%;
        height: 24px;
        padding: 0 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        h4 {
          font-size: 14px;
          width: 170px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        a {
          padding: 0 12px;
          color: #fff;
          height: 18px;
          font-size: 12px;
          border-radius: 10px;
          background-color: #1989fa;
        }
      }
      .right-content {
        width: 100%;
        p {
          font-size: 12px;
          text-indent: 2em;
          color: #ccc;
        }
      }
    }
  }
  // .bottom {
  // }
}
</style>
