<!--
 * @Author: your name
 * @Date: 2021-07-17 21:28:40
 * @LastEditTime: 2021-08-17 15:56:02
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \marketing-channel-platform-front-h5\src\views\search\productlist.vue
-->
<template>
  <div id="productlist">
    <div class="tab">
      <div class="productlist">
        <div class="productcell" v-for="item in data" :key="item.id">
          <div class="commodityCard" @click="commditDetail(item)">
            <img
              :src="
                (item &&
                  item.mediaList &&
                  item.mediaList.length &&
                  item.mediaList[0].url) ||
                icourl
              "
              alt=""
            />
            <!-- <img src="../../assets/img/2.png" alt="" /> -->
            <h4 v-html="`${item.name}`"></h4>
            <div class="price">
              <p>￥{{ item.price }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: { data: Array },
  data() {
    return {
      icourl:'',
      active: 2,
      productcell: [],
    };
  },
  methods: {
    commditDetail(item) {
      this.$router.push(
        `shopdetails?itemCode=${item.itemCode}&storeCode=${item.storeCode}`
      );
    },
  },
};
</script>

<style lang="less" scoped>
.productlist {
  display: flex;
  flex-wrap: wrap;
  .productcell {
    margin-top: 5%;
    width: 50%;
    display: inline-block;
  }

  .commodityCard {
    height: auto;
    margin: 5%;
    display: inline-block;
  }
  .commodityCard {
    width: auto;
    height: auto;
    display: inline-block;
    img {
      // width: 100%;
      height: 149px;
    }
    h4 {
      font-size: 13px;
      text-align: left;
      font-weight: 400;
      margin-top: 1px;
    }
    h2 {
      display: inline-block;
      color: red;
      margin-top: 0;
    }
    i {
      float: right;
    }
  }
  .price {
    margin-top: -12%;
    color: red;
  }
}
</style>
