/*
 * @Autor: syq
 * @Date: 2021-07-15 16:48:17
 */
import request from "../../../request/request";

//根据查询字段列表查询商品
const queryItemList = async (value,currentPage,storeCode) =>
  request({
    url: `/item/getItemList?name=${value}&storeCode=${storeCode}&currentPage=${currentPage}`,
    method: "get",
  });
const stoteItemList = async (value,currentPage) =>
  request({
    url: `/realStore/realStore?store-name=${value}&currentPage=${currentPage}`,
    method: "get",
  });

const queryHistoryList = async () =>
  request({
    url: `/searchHistory`,
    method: "get",
  });

const addHistoryItem = async (value) =>
  request({
    url: `/searchHistory?value=${value}`,
    method: "post",
  });

const deleteHistoryList = async () =>
  request({
    url: `/searchHistory/empty`,
    method: "post",
  });
const deleteHistoryItem = async (value) =>
  request({
    url: `/searchHistory/${value}`,
    method: "delete",
  });
export {
  queryItemList,
  stoteItemList,
  queryHistoryList,
  addHistoryItem,
  deleteHistoryList,
  deleteHistoryItem,
};
